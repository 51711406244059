import * as React from 'react'
import { forwardRef, ForwardedRef } from 'react'
import {
  Animation,
  AnimationHandler,
  AnimationProps,
} from '@conte-ltd/components-animation'

export interface SlideUpAnimation extends AnimationProps {
  moveAmount?: string
}

function _SlideUpAnimation(
  props: SlideUpAnimation,
  fwRef: ForwardedRef<AnimationHandler>
) {
  const { children, moveAmount = '10%', ...otherProps } = props

  return (
    <Animation
      keyframes={[
        { transform: `translateY(${moveAmount})` },
        { transform: 'translateY(0)' },
      ]}
      ref={fwRef}
      {...otherProps}
      options={{ duration: 800, easing: 'ease-out', ...otherProps.options }}
    >
      {children}
    </Animation>
  )
}

export const SlideUpAnimation = forwardRef(_SlideUpAnimation)
