import * as React from 'react'
import { forwardRef, ForwardedRef } from 'react'
import {
  Animation,
  AnimationHandler,
  AnimationProps,
} from '@conte-ltd/components-animation'

export interface SlideRightAnimation extends AnimationProps {
  moveAmount?: string
}

function _SlideRightAnimation(
  props: SlideRightAnimation,
  fwRef: ForwardedRef<AnimationHandler>
) {
  const { children, moveAmount = '10%', ...otherProps } = props

  return (
    <Animation
      keyframes={[
        { transform: `translateX(-${moveAmount})` },
        { transform: 'translateX(0)' },
      ]}
      ref={fwRef}
      {...otherProps}
      options={{ duration: 800, easing: 'ease-out', ...otherProps.options }}
    >
      {children}
    </Animation>
  )
}

export const SlideRightAnimation = forwardRef(_SlideRightAnimation)
