import * as React from 'react'
import { forwardRef, ForwardedRef } from 'react'
import {
  Animation,
  AnimationHandler,
  AnimationProps,
} from '@conte-ltd/components-animation'

export interface SlideRightUpAnimation extends AnimationProps {}

function _SlideRightUpAnimation(
  props: SlideRightUpAnimation,
  fwRef: ForwardedRef<AnimationHandler>
) {
  const { children, ...otherProps } = props

  return (
    <Animation
      keyframes={[
        { transform: 'translate(-90%, 20%)' },
        { transform: 'translate(-80%, 19%)' },
        { transform: 'translate(-70%, 18%)' },
        { transform: 'translate(-60%, 17%)' },
        { transform: 'translate(-50%, 16%)' },
        { transform: 'translate(-40%, 14%)' },
        { transform: 'translate(-30%, 12%)' },
        { transform: 'translate(-20%, 8%)' },
        { transform: 'translate(-10%, 4%)' },
        { transform: 'translate(0, 0)' },
      ]}
      ref={fwRef}
      {...otherProps}
      options={{ duration: 800, easing: 'ease-out', ...otherProps.options }}
    >
      {children}
    </Animation>
  )
}

export const SlideRightUpAnimation = forwardRef(_SlideRightUpAnimation)
