import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { useMediaQuery } from '@conte-ltd/components-utils'
import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import type { Theme } from '../styles/theme'

export function Hero() {
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)

  return (
    <div
      css={css`
        height: 100vh;
      `}
    >
      <div
        css={css`
          position: relative;
          top: 12rem;
          width: 86vw;
          height: calc(100vh - 24rem);
          margin: 0 auto;

          @media ${bp.lg} {
            top: 10rem;
            width: 100vw;
            height: calc(100vh - 10rem);
          }

          @media ${bp.md} {
            top: 8rem;
            height: calc(100vh - 8rem);
          }
        `}
        className={'light-mode'}
      >
        {isMobile ? (
          <StaticImage
            src={'../images/keyvisual_01_sp.jpg'}
            alt="CONTEキービジュアル"
            quality={100}
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        ) : (
          <StaticImage
            src={'../images/keyvisual_01.jpg'}
            alt="CONTEキービジュアル"
            quality={100}
            layout={'fullWidth'}
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        )}
      </div>
    </div>
  )
}
