import { css } from '@emotion/react'
import { Container } from './container'
import { InViewFadeUp } from './in-view-fade'
import { button, Button } from './button'
import * as React from 'react'
import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'
import { useMediaQuery } from '@conte-ltd/components-utils'

export function ToContact() {
  const { color, spacing, fontSize } = useVariables<Theme>()
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)
  const isDesktop = !isMobile

  return (
    <section
      css={css`
        color: ${color.white};
        background: ${color.black};
      `}
      id={'contact'}
      className={'dark-mode'}
    >
      <Container
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: ${spacing.md};
          padding: ${spacing['2xl']} 0;
          text-align: center;
          white-space: nowrap;

          @media ${bp.lg} {
            gap: ${spacing.lg};
            padding: ${spacing['1.5xl']} 0;
          }
        `}
      >
        <h2
          css={css`
            font-size: ${isMobile ? fontSize['6xl'] : fontSize['3xl']};
          `}
        >
          <InViewFadeUp>CONTACT</InViewFadeUp>
        </h2>
        <p>
          <InViewFadeUp>
            ホームページ制作やリニューアル、
            {isMobile && <br />}
            各種デザイン、映像制作から
            <br />
            「こんなこともできる？」といった
            {isMobile && <br />}
            漠然とした想い、ちょっとしたお悩みまで、
            <br />
            こちらからお気軽にご相談ください。
          </InViewFadeUp>
        </p>

        <Button
          css={button(color).white}
          variant={'underline'}
          href={'/contact'}
        >
          VIEW MORE
        </Button>
      </Container>
    </section>
  )
}
