import * as React from 'react'
import { forwardRef, ForwardedRef } from 'react'
import {
  Animation,
  AnimationHandler,
  AnimationProps,
} from '@conte-ltd/components-animation'

export interface FadeInAnimation extends AnimationProps {}

function _FadeInAnimation(
  props: FadeInAnimation,
  fwRef: ForwardedRef<AnimationHandler>
) {
  const { children, ...otherProps } = props

  return (
    <Animation
      keyframes={[{ opacity: 0 }, { opacity: 1 }]}
      ref={fwRef}
      {...otherProps}
      options={{ duration: 600, easing: 'ease-out', ...otherProps.options }}
    >
      {children}
    </Animation>
  )
}

export const FadeInAnimation = forwardRef(_FadeInAnimation)
