import * as React from 'react'
import { forwardRef, ForwardedRef } from 'react'
import {
  Animation,
  AnimationHandler,
  AnimationProps,
} from '@conte-ltd/components-animation'

export interface SlideLeftDownAnimation extends AnimationProps {
  pattern?: 1 | 2
}

function _SlideLeftDownAnimation(
  props: SlideLeftDownAnimation,
  fwRef: ForwardedRef<AnimationHandler>
) {
  const { children, pattern = 1, ...otherProps } = props

  return (
    <Animation
      keyframes={
        pattern === 1
          ? [
              { transform: 'translate(90%, -20%)' },
              { transform: 'translate(80%, -19%)' },
              { transform: 'translate(70%, -18%)' },
              { transform: 'translate(60%, -17%)' },
              { transform: 'translate(50%, -16%)' },
              { transform: 'translate(40%, -14%)' },
              { transform: 'translate(30%, -12%)' },
              { transform: 'translate(20%, -8%)' },
              { transform: 'translate(10%, -4%)' },
              { transform: 'translate(0, 0)' },
            ]
          : [
              { transform: 'translate(10%, -164%)' },
              { transform: 'translate(10%, -154%)' },
              { transform: 'translate(10%, -144%)' },
              { transform: 'translate(10%, -134%)' },
              { transform: 'translate(10%, -124%)' },
              { transform: 'translate(10%, -114%)' },
              { transform: 'translate(10%, -104%)' },
              { transform: 'translate(10%, -94%)' },
              { transform: 'translate(10%, -84%)' },
              { transform: 'translate(10%, -74%)' },
              { transform: 'translate(10%, -64%)' },
              { transform: 'translate(10%, -54%)' },
              { transform: 'translate(10%, -44%)' },
              { transform: 'translate(8%, -34%)' },
              { transform: 'translate(6%, -24%)' },
              { transform: 'translate(4%, -14%)' },
              { transform: 'translate(2%, -4%)' },
              { transform: 'translate(0, 0)' },
            ]
      }
      ref={fwRef}
      {...otherProps}
      options={{ duration: 800, easing: 'ease-out', ...otherProps.options }}
    >
      {children}
    </Animation>
  )
}

export const SlideLeftDownAnimation = forwardRef(_SlideLeftDownAnimation)
