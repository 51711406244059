import {
  AnimationController,
  AnimationHandler,
  useAnimationController,
  useInView,
} from '@conte-ltd/components-animation'
import { range } from '@conte-ltd/components-utils'
import React from 'react'
import { ReactNode, RefObject, useEffect } from 'react'
import type { IntersectionOptions } from 'react-intersection-observer'

interface InViewAnimationControllerChildrenProps {
  intersectionRef: (node?: Element | null | undefined) => void
  refs: RefObject<AnimationHandler>[]
  controllers: AnimationController[]
  inView: boolean
}

export interface InViewAnimationControllerProps extends IntersectionOptions {
  children: (props: InViewAnimationControllerChildrenProps) => ReactNode
  refsNum?: number
}

export function InViewAnimationController({
  children,
  refsNum = 1,
  triggerOnce = true,
  ...otherProps
}: InViewAnimationControllerProps) {
  const { ref: intersectionRef, inView } = useInView({
    triggerOnce,
    ...otherProps,
  })
  const controllers = range(refsNum).map(() => useAnimationController())
  const refs = controllers.map(({ ref }) => ref)

  useEffect(() => {
    const playbackRate = inView ? 1 : -1

    controllers.forEach(({ play, updatePlaybackRate }) => {
      updatePlaybackRate(playbackRate)
      play()
    })
  }, [inView])

  return <>{children({ intersectionRef, refs, controllers, inView })}</>
}
